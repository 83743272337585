
.icono-editar {
  color: blue !important;
  touch-action: auto;
  cursor: pointer;
}

.icono-eliminar {
  color: red !important;
  cursor: pointer;
}

td {
  text-align: center !important;
}
