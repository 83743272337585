i {
  color: #D1D1D1;
}

button {
  margin: 4%;
}

.checkbox {
  margin: 4%;
}
