.check-box {
  margin: auto;
  position: relative;
  display: block;
  size: 100% !important;
}

h5 {
  margin: auto;
  position: relative;
  display: block;
}

h3 {
  color: black !important;
}

i {
  color: gray;
}

