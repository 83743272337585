.blue {
  background-color: #007bff !important;
}

.card-title {
  color: white;
}

.center {
  display: flex !important;
  text-align: center;
  justify-content: center;
}

.centrar {
  /* display: flex !important; */
  width: 80%;
  text-align: center;
  justify-content: center !important;
  margin: auto;
}

.error {
  border-color: #ff6464 !important;
  border-width: 2px !important;
}
