.modal-content {
    background-color: white !important;
    border-radius: 25px !important;
  }
  
  .modal-header {
    padding: 15px !important;
    border: 0px !important;
  }
  
  .modal-body {
    padding: 20px, 40px, 40px, 40px !important;
  }
  
  .modal-title {
    margin: 0 auto !important;
  }
  
  .img-modal {
    width: 50px !important;
  }

  .icon{
    text-align: center;
    font-size: 25px;
    margin-left: 750px;
    margin-top: -80px;
    cursor: pointer;
  }
  